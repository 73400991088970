/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
    dailysales: [],
    weeklysales: [],
    forcast: [],
    eventlist: [],
    hourlyweekwise: [],
    hourlydaywise: [],
    perhours: [],
    rotafc: [],
  };
  export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
      case "DAILY_SALES": {
        return {
          ...state,
          dailysales: action.payload
        };
      }
      case "WEEKLY_SALES": {
        return {
          ...state,
          weeklysales: action.payload
        };
      }
      case "FORCAST_SALES": {
        return {
          ...state,
          forcast: action.payload
        };
      }
      case "EVENT_LIST": {
        return {
          ...state,
          eventlist: action.payload
        };
      }
      case "HOURLY_WEEKWISE": {
        return {
          ...state,
          hourlyweekwise: action.payload
        };
      }
      case "ROTA_WEEKWISE": {
        return {
          ...state,
          rotafc: action.payload
        };
      }
      case "HOURLY_DAYWISE": {
        return {
          ...state,
          hourlydaywise: action.payload
        };
      }
      case "PER_HOURS": {
        return {
          ...state,
          perhours: action.payload.result
        };
      }
      default: {
        return state;
      }
    }
  }
  