// eslint-disable-next-line import/no-webpack-loader-syntax
import "./antd-overrides.less.css!=!less-loader?{'lessOptions':{'javascriptEnabled':true}}!./antd-overrides.less";
import React, { Fragment, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AsyncLoadable from "@/utils/AsyncLoadable";
import "animate.css";
import "react-calendar-timeline/lib/Timeline.css";

const DefaultLayout = AsyncLoadable(() =>
  import(/* webpackChunkName: 'default' */ "./containers")
);
const View404 = AsyncLoadable(() =>
  import(/* webpackChunkName: '404' */ "./views/Others/404")
);
const View500 = AsyncLoadable(() =>
  import(/* webpackChunkName: '500' */ "./views/Others/500")
);
const Login = AsyncLoadable(() =>
  import(/* webpackChunkName: 'login' */ "./pages/Login")
);

const ResetPassword = AsyncLoadable(() =>
  import(/* webpackChunkName: 'login' */ "./pages/Login/resetPassword")
);

const App = () => {
  return (
    <Fragment>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/login" />} />
        <Route path="/500" component={View500} />
        <Route path="/login" component={Login} />
        <Route path="/response-password-reset" component={ResetPassword} />
        <Route path="/404" component={View404} />
        <Route component={DefaultLayout} />
      </Switch>
    </Fragment>
  );
};

export default App;
