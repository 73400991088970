const INITIAL_STATE = {
  list: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "EMPLOYEE_LIST": {
      return {
        ...state,
        list: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
