import { combineReducers } from "redux";
import auth from "./auth";
import dashboard from "./dashboard";
import account from "./account";
import loader from "./loader";
import operation from "./operation";
import store from "./store";
import complaince from "./complaince";
import employee from "./employee";
import hiring from "./hiring";
import sales from "./sales";

//import setting from "./setting";
const appReducer = combineReducers({
  auth,
  account,
  dashboard,
  loader,
  operation,
  store,
  complaince,
  employee,
  hiring,
  sales
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
