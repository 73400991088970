const INITIAL_STATE = {
  list: null,
  bilingInfo: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "QUEUE_REPORT": {
      return { ...state, list: action.payload };
    }
    case "BILING_DETAILS": {
      return { ...state, bilingInfo: action.payload };
    }

    default: {
      return state;
    }
  }
}
