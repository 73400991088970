const INITIAL_STATE = {
  info: null,
  binfo: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ACCOUNT_INFO": {
      return {
        ...state,
        info: action.payload
      };
    }
    case "BUCKET_INFO": {
      return {
        ...state,
        binfo: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
