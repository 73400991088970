const INITIAL_STATE = {
  list: null,
  complainces: null,
  operations: null,
  auditlist: null,
  allaudit: null,
  single: null,
  rota: null
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "STORE_LIST": {
      return {
        ...state,
        list: action.payload
      };
    }
    case "COMPLAINCE_STORE_BY_ID": {
      return {
        ...state,
        complainces: action.payload
      };
    }
    case "OPERATION_STORE_BY_ID": {
      return {
        ...state,
        operations: action.payload
      };
    }
    case "GET_AUDIT_LIST_QUES": {
      return {
        ...state,
        auditlist: action.payload
      };
    }
    case "GET_AUDIT_LIST": {
      return {
        ...state,
        allaudit: action.payload
      };
    }
    case "GET_AUDIT_BY_ID": {
      return {
        ...state,
        single: action.payload
      };
    }
    case "GET_ROTA": {
      return {
        ...state,
        rota: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
