const INITIAL_STATE = {
  list: null,
  person: null,
  nationality: null,
  country: null,
  contacts: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "HIRING_LIST": {
      return {
        ...state,
        list: action.payload.result
      };
    }
    case "UNDER_HIRING_USER": {
      return {
        ...state,
        person: action.payload
      };
    }
    case "NATIONALITY_LIST": {
      return {
        ...state,
        nationality: action.payload
      };
    }
    case "COUNTRY_LIST": {
      return {
        ...state,
        country: action.payload
      };
    }
    case "CONTACTS": {
      return {
        ...state,
        contacts: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
