import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const environment = process.env.REACT_APP_NODE_ENV;
const initialState = {
  auth: {
    authenticated: {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      name: localStorage.getItem("name"),
      valid: localStorage.getItem("valid"),
      role: localStorage.getItem("role"),
      userid: localStorage.getItem("userid"),
      store: localStorage.getItem("store"),
    },
  },
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
